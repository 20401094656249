import React, { useState } from "react"
import axios from "axios"
import styled from "styled-components"
import DesignTokens from "./designTokens"

const ContactForm = () => {
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [body, setBody] = useState("")

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true)
    const options = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
    const data = {
      subject: subject,
      email: email,
      body: body,
      subscribedAt: new Date().toISOString(),
    }
    axios
      .post(
        "https://hooks.zapier.com/hooks/catch/7165528/ocvah6q/",
        data,
        options
      )
      .then(function (response) {
        setSuccess(true)
        setLoading(false)
      })
      .catch(function (error) {
        // console.log(error)
        setLoading(false)
      })
  }

  return (
    <Wrapper id="form">
      <form method="post" name="download" onSubmit={handleSubmit}>
        <section>
          <label htmlFor="email">Email mittente *</label>
          <input
            onChange={e => setEmail(e.target.value)}
            type="email"
            name="email"
            id="email"
            value={email}
            required
          />
        </section>
        <section>
          <label htmlFor="subject">Oggetto *</label>
          <input
            onChange={e => setSubject(e.target.value)}
            type="text"
            name="subject"
            id="subject"
            value={subject}
            required
          />
        </section>
        <section>
          <label htmlFor="body">Messaggio *</label>
          <textarea
            onChange={e => setBody(e.target.value)}
            type="body"
            name="body"
            id="body"
            value={body}
            required
          />
        </section>
        <section>
          <p>
            Dopo aver preso visione della presente informativa sulla privacy ai
            sensi dell'ART.13 D.Lgs. n.196 del 2003, a) Acconsento al
            trattamento dei dati personali al fine esclusivo di avere
            informazioni riguardo i servizi relativi al presente sito web. Per
            completare la registrazione è necessaria l'autorizzazione al
            trattamento dei dati personali.
          </p>
        </section>
        <Checkbox>
          <input type="checkbox" id="privacy" name="privacy" required />
          <label htmlFor="privacy">Si, acconsento</label>
        </Checkbox>
        <section>
          {!success && (
            <input type="submit" value={loading ? `Caricamento...` : `Invia`} />
          )}
          {success && (
            <input type="submit" value="Richiesta inviata!" disabled />
          )}
        </section>
      </form>
    </Wrapper>
  )
}

export default ContactForm

const Checkbox = styled.div`
  display: flex;
  flex-direction: row;
  input {
    margin-right: 0.5rem;
  }
  label {
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 0 !important;
  }
`

const Wrapper = styled.div`
  border-radius: ${DesignTokens.borderRadius};
  margin-bottom: 6rem;
  form {
    padding-top: 2rem;
    label {
      margin-bottom: 0.25rem;
      color: black;
      font-size: 14px;
    }
    input,
    textarea {
      padding: ${DesignTokens.spacing[3]};
      color: black;
      border: none;
      font-size: 16px;
      background: transparent;
      border-bottom: 1px solid ${DesignTokens.colors.primary[400]};
      &:hover,
      &:focus {
        border-bottom: 1px solid ${DesignTokens.colors.tertiary[500]};
      }
    }

    input[type="submit"] {
      background: ${DesignTokens.colors.primary[500]};
      border: none;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      cursor: pointer;
      &:hover,
      &:active {
        background: ${DesignTokens.colors.tertiary[500]};
      }
      &:disabled {
        background: #4caf50;
        cursor: default;
      }
    }
    section {
      display: flex;
      flex-direction: column;
      p {
        font-size: 12px;
        color: ${DesignTokens.colors.primary[200]};
      }
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[4]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
