import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { Body, Display } from "../components/typography"
import PageTitle from "../components/pageTitle"
import Gut from "../components/gut"
import ContactForm from "../components/contactForm"

const ContactPage = () => {
  const map = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2785.475027304214!2d9.655269215566047!3d45.72156757910472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4781514d9d6788d5%3A0x946db4848b82eda7!2sEditrice%20San%20Marco!5e0!3m2!1sit!2sit!4v1609773113517!5m2!1sit!2sit" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`
  return (
    <Layout>
      <SEO title="Contatti" />
      <Wrapper>
        <Container>
          <Row>
            <Col>
              <PageTitle>
                <Display align="center">Contatti</Display>
              </PageTitle>
            </Col>
          </Row>
          <Gut gutter={4}>
            <Row>
              <Col md={4}>
                <Gut gutter={1}>
                  <div>
                    <Body>Per contattarci</Body>
                  </div>
                  <div>
                    <Body>
                      <strong>E-mail:</strong>{" "}
                      <a href="mailto:info@editricesanmarco.it">
                        info@editricesanmarco.it
                      </a>
                      <br />
                      <strong>Telefono:</strong> 035 4128249
                      <br />
                      <strong>Fax:</strong> 035 944385
                    </Body>
                  </div>
                  <div>
                    <Body>
                      La <strong>nostra sede</strong> si trova in via
                      <br />
                      Pontesecco 9/bis
                      <br />
                      24010 Ponteranica - Bergamo
                    </Body>
                  </div>
                </Gut>
              </Col>
              <Col dangerouslySetInnerHTML={{ __html: map }} />
            </Row>
            <Row justify="center">
              <Col md={8}>
                <ContactForm />
              </Col>
            </Row>
          </Gut>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default ContactPage
